<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="bloc-item">
        <h6 class="title-h6 process-color">{{$t('processes.objetReseau')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <div class="row q-col-gutter-sm">
            <div class="col">
              <h6 class="title-h6 q-mt-none">{{$t('processes.origine_wallet')}}</h6>
              <div class="row q-col-gutter-md q-mb-md">
                <add-a-wallet :max="1" :fullWidth="true" v-if="currentTransfertPoretefeuilleProcess && currentTransfertPoretefeuilleProcess.wallet_initial" @walletsUpdated="updateWallet(0, $event)" :wallets="[currentTransfertPoretefeuilleProcess.wallet_initial]" class="col"/>
                <add-a-wallet :max="1" :fullWidth="true" v-else @walletsUpdated="updateWallet(0, $event)" class="col"/>
              </div>
            </div>
            <div class="col">
              <h6 class="title-h6 q-mt-none">{{$t('processes.cible_wallet')}}</h6>
              <div class="row q-col-gutter-md q-mb-md">
                <add-a-wallet :max="1" :fullWidth="true" v-if="currentTransfertPoretefeuilleProcess && currentTransfertPoretefeuilleProcess.wallet_cible" @walletsUpdated="updateWallet(1, $event)" :wallets="[currentTransfertPoretefeuilleProcess.wallet_cible]" class="col"/>
                <add-a-wallet :max="1" :fullWidth="true" v-else @walletsUpdated="updateWallet(1, $event)" class="col"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md">
        <h6 class="title-h6">{{$t('processes.transfert')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm q-mb-md collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-select class="col q-mt-none" v-bind="formInputProps('type_transfert')" :color="color"
                v-model="formData.type_transfert" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_transfert')" :color="color"
                v-model="formData.date_transfert" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_accord_agent_perdant')" :color="color"
                v-model="formData.date_accord_agent_perdant" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_accord_agent_gagnant')" :color="color"
                v-model="formData.date_accord_agent_gagnant" ref="label" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div>
        <h6>{{$t('processes.listContrat')}}</h6>
        <div class="row q-gutter-md ">
          <div v-if="relatedLinks.length" class="column col col-6 q-py-md q-col-gutter-md">
            <div class="row q-gutter-sm" v-for="relatedLink in relatedLinks" :key="relatedLink.id">
              <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-12" :actions="relatedLink.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
              </base-card>
            </div>
          </div>

          <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
            <base-add-button :disabled="!isUpdate" :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
          </div>

          <div v-else class="col-6">
            <q-card flat bordered class="q-pa-md">
              <base-input-text v-bind="titleTemp" :color="color"
                  v-model="link.titre" ref="admin_note" />
              <base-input-text v-bind="linkTemp" :color="color"
                  v-model="link.lien" ref="admin_note" />
              <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink()">{{$t('form.ok')}}</q-btn>
              <div style="clear: both;"></div>
            </q-card>
          </div>
        </div>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.linked_suivi_des_ic')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div row">
          <div v-if="relatedSuiviIc.length" class="row full-width q-col-gutter-md q-pt-sm">
            <div class="col-12" v-for="suiviIc in relatedSuiviIc" :key="suiviIc.id">
              <base-card @menuClick="onMenuClick" class="col-12" :actions="suiviIc.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="playlist_add_check" />
                <q-item-label @click="$router.push({ name: 'suivi-des-ic-form', params: { id: suiviIc.id } })" class="cursor-pointer q-mb-md"><strong>{{$t('processes.suivi_des_ic')}}</strong></q-item-label>
                <div class="row">
                  <div class="col" v-for="obj in suiviIc.wallets" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="account_balance_wallet" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.NOMPTF}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-for="obj in suiviIc.agents" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="accessibility" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.TITULAIRE}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </base-card>
            </div>
          </div>
          <div v-else class="col-6" style="height:80px">
            <base-add-button :disabled="!isUpdate" :label="$t('processes.create_linked_suivi_des_ic')" :color="color" @click="createLinkedProcess('suiviIc')" />
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import addAWallet from '../shared/addAWallet'

export default {
  mixins: [BaseForm],
  components: { addAWallet },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      linkedAgent: null,
      // For links
      relatedLinks: [],
      isLinksFormOpen: false,
      titleTemp: { label: 'Titre du lien' },
      linkTemp: { label: 'Chemin réseau' },
      link: {
        titre: '',
        lien: ''
      },
      // Related processes
      relatedProcessLinked: []
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentTransfertPoretefeuilleProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),
    relatedSuiviIc: {
      get () {
        return this.relatedProcessLinked.length ? this.relatedProcessLinked.filter(process => process.type === 'suiviIc') : []
      },
      set (newVal) {
        this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'suiviIc')
        this.formData.related_objects.push(...newVal.map(suiviIc => ({ model_type: 'suiviIc', model_id: suiviIc.id })))
        this.relatedProcessLinked = this.relatedProcessLinked.filter(process => process.type !== 'suiviIc')
        this.relatedProcessLinked.push(...newVal)
      }
    }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'transfertPortefeuille'
        if (!newVal || !this.currentTransfertPoretefeuilleProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options }) => ({ id, options }))
            .reduce((acc, { id, options }) => ({
              ...acc, [id]: options ? options.find(({ value }) => value === this.currentTransfertPoretefeuilleProcess[id]) : this.currentTransfertPoretefeuilleProcess[id]
            }), {})
        }

        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id
          this.formData.related_objects = []

          // Set wallets data
          this.formData.wallet_initial_id = this.currentTransfertPoretefeuilleProcess.wallet_initial_id
          if (this.currentTransfertPoretefeuilleProcess.wallet_initial_id) {
            this.formData.related_objects.push({
              model_type: 'walletInitial',
              model_id: this.currentTransfertPoretefeuilleProcess.wallet_initial_id
            })
          }
          this.formData.wallet_cible_id = this.currentTransfertPoretefeuilleProcess.wallet_cible_id
          if (this.currentTransfertPoretefeuilleProcess.wallet_cible_id) {
            this.formData.related_objects.push({
              model_type: 'walletCible',
              model_id: this.currentTransfertPoretefeuilleProcess.wallet_cible_id
            })
          }

          // Set process suivi IC lié
          if (this.currentTransfertPoretefeuilleProcess.suivi_ics) {
            let suiviIcFormDataMapped = this.currentTransfertPoretefeuilleProcess.suivi_ics.map(suiviIc => ({
              model_type: 'suiviIc',
              model_id: suiviIc.id
            }))
            this.formData.related_objects.push(...suiviIcFormDataMapped)

            let suiviIcLinkedMapped = this.currentTransfertPoretefeuilleProcess.suivi_ics.map(suiviIc => ({
              type: 'suiviIc',
              id: suiviIc.id,
              wallets: suiviIc.wallets,
              agents: suiviIc.agents,
              actions: [
                { label: this.$t('processes.unlink_suiviIc'), payload: { name: 'unlink-suiviIc', params: { id: suiviIc.id } } }
              ]
            }))
            this.relatedProcessLinked.push(...suiviIcLinkedMapped)
          }
          // Set links
          this.relatedLinks = this.currentTransfertPoretefeuilleProcess.liens ? JSON.parse(this.currentTransfertPoretefeuilleProcess.liens).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
        }
        this.formFields.find(form => form.id === 'liens').type = 'text'
      },
      immediate: true
    },
    relatedLinks (newVal, oldVal) {
      this.formData.liens = newVal.length > 0 ? newVal.filter(lien => lien.id !== null).map(({ titre, lien, id }) => ({ titre, lien, id })) : []
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_de_transfert_portefeuille'), to: { name: 'suivi-de-transfert-portefeuille-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-de-transfert-portefeuille-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_de_transfert_portefeuille'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-de-transfert-portefeuille-form', label: 'Informations', to: { name: 'suivi-de-transfert-portefeuille-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'transfertPortefeuille' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'transfertPortefeuille')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'transfertPortefeuille')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    createLinkedProcess (process) {
      this.$store.dispatch('processes/createLinkedProcess', { linkedProcess_type: process, process_id: this.$route.params.id, process: 'transfertPortefeuille' }).then(datas => {
        if (!this.formData.related_objects) this.formData.related_objects = []
        datas.count.forEach(linkedObj => {
          this.relatedProcessLinked.push({
            id: linkedObj.id,
            type: process,
            wallets: linkedObj.wallets,
            agents: linkedObj.agents,
            actions: [
              { label: this.$t(`processes.unlink_${process}`), payload: { name: `unlink-${process}`, params: { id: linkedObj.id } } }
            ]
          })
          this.formData.related_objects.push({
            model_type: process,
            model_id: linkedObj.id
          })
        })
      })
    },
    addLink () {
      if (this.link.titre === '' || this.link.lien === '') return
      let tempId = 0
      if (!this.relatedLinks) {
        this.relatedLinks = []
      } else if (this.relatedLinks.length) {
        console.log(this.relatedLinks)
        tempId = Math.max.apply(Math, this.relatedLinks.map(link => link.id)) + 1
        console.log('ato', tempId)
      }

      // Remove " first before adding
      let copiedObject = Object.assign({}, this.link)
      copiedObject.id = tempId
      copiedObject.lien = copiedObject.lien.replace(/"/g, '')
      copiedObject.actions = [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'delete-link', params: { id: tempId } } }]

      this.relatedLinks.push(copiedObject)
      this.link.titre = ''
      this.link.lien = ''
      this.isLinksFormOpen = false
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-de-transfert-portefeuille-form', params: { id } })
    },
    copyCour () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    updateWallet (type, wallets) {
      if (wallets && wallets.length) {
        if (type === 0) {
          this.formData.wallet_initial_id = wallets[0].id
          this.formData.related_objects.push({
            model_type: 'walletInitial',
            model_id: wallets[0].id
          })
        } else if (type === 1) {
          this.formData.wallet_cible_id = wallets[0].id
          this.formData.related_objects.push({
            model_type: 'walletCible',
            model_id: wallets[0].id
          })
        }
      } else {
        if (type === 0) {
          this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'walletInitial')
          this.formData.wallet_initial_id = null
        } else if (type === 1) {
          this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'walletCible')
          this.formData.wallet_cible_id = null
        }
      }
    },
    onSelectLinkedObj (obj) {
      console.log(obj)
      this.linkedAgent = {
        label: obj.value.label,
        id: obj.value.value,
        actions: [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } }
        ]
      }
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        this.linkedAgent = null
        this.searchBoxActivated = false
      }
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'transfertPortefeuille', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .process-color
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
